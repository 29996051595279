import React from 'react';
import header from './images/header.jpeg';
import './App.css';
import Register from './Register';

function App() {
  return (
    <div className="App">
      <header></header>
      <h1>Welcome to the Xledger Empire!</h1>
      <Register />
      <footer></footer>
    </div>
  );
}

export default App;
