import React, { SyntheticEvent, useState } from 'react'
import './Register.css';
import { apiUrl } from './config/api';

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');
    const [form, setForm] = useState({
        login: '',
        password: '',
        confirm: '',
        email: '',
    })

    const registerAccount = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (!form.login || !form.password || !form.email) {
            return alert('Values can\'t be empty!')
        }
        if (form.login.length < 14 || form.login.length > 50) {
            return alert('Xumm wallet address cant be shorter than 14 characters and longer than 50.')
        }
        if (form.password !== form.confirm) {
            return alert('Passwords don\'t match!')
        }

        setLoading(true);
        try {
            const check = await fetch(`${apiUrl}/register/check/${form.login}`);
            const checkResult = await check.json();
            if (checkResult) {
                return alert('Account is already exist!');
            }



            const res = await fetch(`${apiUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...form
                }),
            })

            const data = await res.json();

            setId(data.id);

            setTimeout(() => {
                setId('')
                setForm({
                    login: '',
                    password: '',
                    confirm: '',
                    email: '',
                })
            }, 5000)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const updateForm = (key: string, value: any) => {
        setForm(form => ({
            ...form,
            [key]: value,
        }))
    }

    if (loading) {
        return <>
            <h2>Trwa rejestracja...</h2>
        </>
    }

    if (id) {
        return <>
            <h2>Twoje konto "{form.login}" zostało poprawnie dodane do serwisu pod ID: {id}. Zaraz nastąpi przekierowanie!</h2>
        </>
    }

    return (
        <form action="/register" onSubmit={registerAccount}>
            <h2>Create your account:</h2>
            <label htmlFor="login">Xumm Wallet Address:</label>
            <input type="text" id="login" name="login"
                value={form.login}
                onChange={e => updateForm('login', e.target.value)}
            />

            <label htmlFor="password">Password:</label>
            <input type="password" id="password" name="password"
                value={form.password}
                onChange={e => updateForm('password', e.target.value)}
            />

            <label htmlFor="confirm">Confirm password:</label>
            <input type="password" id="confirm"
                value={form.confirm}
                onChange={e => updateForm('confirm', e.target.value)}
            />

            <label htmlFor="email">Email:</label>
            <input type="email" name="email" id="email"
                value={form.email}
                onChange={e => updateForm('email', e.target.value)}
            />

            {/* <label htmlFor="character">Choose your character</label>
            <select name="character" id="character" value={form.character}
                onChange={e => updateForm('character', e.target.value)}
            >
                <option value=""></option>
                <option value="knight">Knight</option>
                <option value="wizard">Wizard</option>
                <option value="viking">Viking</option>
                <option value="samurai">Samurai</option>
            </select> */}

            <button>Confirm</button>
        </form>
    );
}

export default Register;